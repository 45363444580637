<template>
  <v-app-bar
    v-if="false !== $route.meta.menu"
    :elevation="0"
    app
    class="py-0"
    height="56"
    color="navbarColor"
  >
    <v-btn depressed text square @click.stop="setDrawer(!drawer)" class="mr-2">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <div class="font-mangueira">
      {{ title }}
      <v-chip
        v-if="route.meta.deprecated"
        class="ml-2"
        color="red"
        small
        text-color="white"
      >
        {{ $lang.catalog.deprecated }}
      </v-chip>
    </div>

    <v-spacer />

    <v-expand-transition>
      <div
        v-if="
          (budgetsForRenewal.length || budgetsForUpdate.length) &&
          route.name !== 'budget-renewal' &&
          route.name !== 'budget-update'
        "
        class="px-4"
        style="width: 230px"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark depressed style="width: 100%" v-on="on">
              <span class="text-overline pl-2">
                {{ $lang.menu.currentActions }}
              </span>
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="budgetsForRenewal.length">
              <v-list-item-title
                class="editable"
                style="cursor: pointer; text-align: left"
                @click="redirection('renewal')"
              >
                {{
                  `${budgetsForRenewal.length}
                budget${budgetsForRenewal.length > 1 ? 's' : ''} ${
                    $lang.menu.waitingForRenewal
                  }`
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="budgetsForUpdate.length">
              <v-list-item-title
                class="editable"
                style="cursor: pointer; text-align: left"
                @click="redirection('update')"
              >
                {{
                  `${budgetsForUpdate.length}
                budget${budgetsForUpdate.length > 1 ? 's' : ''} ${
                    $lang.menu.waitingForUpdate
                  }`
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                class="editable"
                style="text-align: left"
                @click="reset"
              >
                {{ $lang.menu.cancelCurrentActions }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-expand-transition>
    <workflow-manager></workflow-manager>
    <user-profile></user-profile>
    <company-dialog v-model="changeCompany" />
  </v-app-bar>
</template>

<script setup>
import { ref, computed, defineProps, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'

import UserProfile from '@/components/layouts/UserProfile.vue'
import CompanyDialog from '@/components/global/CompanyDialog'
import WorkflowManager from '@/components/layouts/workflow/WorkflowManager.vue'

const instance = getCurrentInstance().proxy
const $store = computed(() => instance.$store)
const $route = computed(() => instance.$route)
const router = useRouter()
const route = useRoute()

const props = defineProps({ title: { type: String, default: 'Lota.cloud' } })

const changeCompany = ref(false)
const loading = ref(false)
const user = computed(() => $store.value.getters.user)
const drawer = computed(() => $store.value.getters.drawer)
const hasPermission = $store.value.getters.hasPermission
const setDrawer = (v) => $store.value.commit('setDrawer', v)
const newBudget = computed(() => $store.value.getters.getNewBudget)
const budgetsForRenewal = computed(() => {
  return newBudget.value.filter((b) => b.renewal === true)
})
const budgetsForUpdate = computed(() => {
  return newBudget.value.filter((b) => b.renewal === false)
})

function redirection(type) {
  router.push({ name: `budget-${type}` })
}

function reset() {
  $store.value.commit('resetsNewBudgets')
}
</script>

<style scoped>
>>> .v-toolbar__content {
  border-bottom: 1px solid #e0e0e0;
  padding: 0 0 0 12px !important;
}
>>> .v-btn:not(.v-btn--round).v-size--default {
  padding: 0;
  min-width: 36px;
}
.v-icon {
  transition: none !important;
}
</style>
