<template>
  <div class="user-profile-container">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      attach="#user-display"
      bottom
      class="menu"
      close-delay="400"
      content-class="elevation-0"
      nudge-bottom="55"
      nudge-left="203"
      right
    >
      <template v-slot:activator="{ on }">
        <div
          id="user-display"
          :style="menu ? { backgroundColor: primaryColor } : {}"
          class="user-profile-btn"
          @click="menu = !menu"
          v-on="on"
        >
          <v-avatar v-if="hasGravatar" class="mt-2" size="38">
            <v-gravatar :email="user.email"></v-gravatar>
          </v-avatar>
          <v-icon v-if="!hasGravatar && !menu" large>
            mdi-account-outline
          </v-icon>
          <v-icon v-if="!hasGravatar && menu" large>mdi-account</v-icon>
        </div>
      </template>
      <v-card v-model="menu" class="card" flat outlined width="260px">
        <v-list class="pa-0 ma-0">
          <v-list-item class="justify-center mt-4 pa-0">
            <company-logo max-height="48px"></company-logo>
          </v-list-item>
          <v-list-item class="justify-center pa-0 ma-0">
            <span
              v-if="user.firstname && user.firstname"
              class="font-mangueira-b"
            >
              {{ `${user.firstname} ${user.lastname}` }}
            </span>
          </v-list-item>
          <v-divider />
          <div class="text-center mt-3 btn-container">
            <v-btn
              class="justify-center edit-btn pa-3"
              color="primary"
              depressed
              @click="editUser = true"
            >
              <span class="font-mangueira-m">
                {{ $lang.card.editMyProfile }}
              </span>
            </v-btn>
          </div>
          <div class="text-center mt-1 mb-2 btn-container">
            <v-btn
              class="justify-center logout-btn pa-3 white--text"
              color="secondary"
              depressed
              @click="logout"
            >
              <span class="font-mangueira-m">
                {{ $lang.card.logout }}
              </span>
            </v-btn>
          </div>
          <v-divider v-if="hasPermission('switch.company')" class="mb-1" />
          <template v-if="hasPermission('switch.company')">
            <span class="pl-4 admin-title">
              {{ $lang.settings.adminUserMenuTitle }}
            </span>
            <div class="text-center mt-2 mb-2 btn-container">
              <v-btn
                class="justify-center edit-btn pa-3"
                color="primary"
                depressed
                @click="changeCompany = true"
              >
                <span class="font-mangueira-m">
                  {{ $lang.company.changeCompany }}
                </span>
              </v-btn>
            </div>
          </template>
        </v-list>
      </v-card>
    </v-menu>
    <edit-user-modal
      v-if="editUser === true"
      :editUser="editUser"
      @close="close"
    ></edit-user-modal>
    <CompanyDialog v-model="changeCompany" />
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useSessionClear } from '@/composables/session'
import { useColor } from '@/composables/color'
const { lightenHexColor } = useColor()
import { useHasGravatar } from '@/composables/gravatar'

import EditUserModal from '@/components/layouts/EditUserModal.vue'
import CompanyLogo from '@/components/viewers/CompanyLogo.vue'
import CompanyDialog from '@/components/global/CompanyDialog.vue'

const instance = getCurrentInstance().proxy
const $store = computed(() => instance.$store)
const router = useRouter()

const user = computed(() => $store.value.getters.user)
const menu = ref(false)
const editUser = ref(false)
const changeCompany = ref(false)
const hasPermission = $store.value.getters.hasPermission
const hasGravatar = ref(false)

const primaryColor = computed(() => {
  return lightenHexColor(instance.$vuetify.theme.themes.light.primary, 0.7)
})

async function checkGravatar(email) {
  hasGravatar.value = await useHasGravatar(email)
}

onMounted(async () => {
  if (user.value && user.value.email) {
    await checkGravatar(user.value.email)
  }
})

function logout() {
  // clear session
  useSessionClear()
}

function close() {
  editUser.value = false
}
</script>

<style lang="scss" scoped>
.user-profile-container {
  height: 100%;
  border-left: 1px solid #e0e0e0;
}

.user-profile-btn {
  width: 56px;
  height: 55px;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.user-profile-btn:hover {
  background-color: rgba(219, 219, 219, 0.3);
  cursor: pointer;
}

.admin-title {
  font-size: 14px;
  font-weight: 200;
}

.card {
  border-radius: 0 0 0 6px;
}

>>> .card {
  z-index: 9999;
}

.edit-btn,
.logout-btn {
  width: 180px;
}

.btn-container {
  height: 40px;
}
</style>
