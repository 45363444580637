export default {
  namespaced: false,
  state: () => ({
    notifications: [],
  }),
  getters: {
    notifications: (state) => state.notifications,
  },
  mutations: {
    addMessage: (state, snackbar) => {
      state.notifications.push(snackbar)
    },
    removeMessage: (state, index) => {
      state.notifications.splice(index, 1)
    },
    removeAllMessages: (state) => {
      state.notifications = []
    },
  },
  actions: {
    pushMessage: (
      { commit },
      { key, color = 'primary', icon = 'mdi-information' },
    ) => {
      commit('addMessage', { key, color, icon })
    },
    clearAllMessages: ({ commit }) => {
      commit('removeAllMessages')
    },
  },
}
