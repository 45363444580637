module.exports = {
  title: 'Your processes',
  done: 'Completed',
  running: 'In Progress',
  error: 'An incident has been identified in the following process :',
  errorTeamText:
    'Our team has been informed and is working to resolve this incident.',
  errorQuestion: 'Do you have a question ?',
  errorBtnText: 'Contact Support',
  actions: 'Other Actions',
  dataImport: 'Data Import',
  aggregateDimensions: 'Calculate Dimensions',
  checkBudgets: 'Calculate Budgets',
  checkAlerts: 'Calculate Alerts',
}
