module.exports = {
  and: 'ET',
  or: 'OU',
  cost: 'Coût HT',
  providers: 'Fournisseurs',
  provider: 'Fournisseur',
  products: 'Produits',
  product_code: 'Produits',
  product: 'Produit',
  accounts: 'Comptes',
  account: 'Compte',
  regions: 'Régions',
  region: 'Région',
  categories: 'Catégories',
  category: 'Catégorie',
  tags: 'Étiquettes',
  tag: 'Étiquette',
  usage: 'Consommations',
  resources: 'Ressources',
  resourceId: 'Ressources',
  resource_id: 'Ressources',
  resourceName: 'Nom de resource',
  resource_name: 'Nom de resource',
  resourceNameGroupLabel: 'Grouper par nom de resource',
  costCenter: 'Centre de coûts',
  cost_center: 'Centre de coûts',
  resourceTypes: 'Types de ressource',
  resourceType: 'Type de ressource',
  usageTypes: "Types d'usage",
  usageType: "Type d'usage",
  usageAccountId: 'Comptes',
  usage_account_id: 'Comptes',
  resourceCount: 'Nombre de ressources',
  percentage: 'Pourcentage du coût total HT',
  showAll: 'Voir tout',
  totalCostWithoutTaxes: 'Coût total HT',
  period: 'Période',
  dayLabel: 'Dépenses par jour',
  monthLabel: 'Dépenses par mois',
  yearLabel: 'Dépenses par année',
  weekLabel: 'Dépenses par semaines',
  loadingData: 'Chargement de vos données...',
  yAxisChartTitle: 'Dépenses en ',
  quarterLabel: 'Dépenses par trimestres',
  resourceIdGroupLabel: 'Grouper par ressources',
  productGroupLabel: 'Grouper par produits',
  categoryGroupLabel: 'Grouper par catégories',
  usageAccountIdGroupLabel: 'Grouper par comptes',
  regionGroupLabel: 'Grouper par régions',
  serviceGroupLabel: 'Grouper par services',
  providerGroupLabel: 'Grouper par fournisseurs',
  costCenterGroupLabel: 'Grouper par centre de coûts',
  tagGroupLabel: 'Grouper par étiquette',
  usageGroupLabel: 'Grouper par consommations',
  dayGranularity: 'jour',
  monthGranularity: 'mois',
  yearGranularity: 'année',
  weekGranularity: 'semaine',
  quarterGranularity: 'Trimestre',
  showLegend: 'Légende',
  averageCost: 'Coût HT moyen',
  selectFilterScope: 'Sélectionnez un critère de filtre sur le côté gauche',
  noFilterItems: 'Aucun filtre disponible',
  otherChartOptions: 'options',
  detailedView: 'Ce qui vous a été facturé',
  share: 'Partager',
  usages: 'Consommation des services',
  taxes: 'Taxes',
  discounts: 'Réductions',
  fees: 'Frais',
  itemType: 'Type',
  usageDescription: 'Description',
  usageQuantity: 'Quantité',
  usageUnit: 'Unité',
  usageCost: 'Coût',
  allModeLabel: 'Toutes les dépenses',
  noDataText: 'Aucun résultat trouvé',
  costCenterOnlyForAzure:
    "Le tri par centre de coûts n'est disponible que pour Azure actuellement.",
  caseSensitiveSearch: 'Sensible à la casse',
  caseInsensitiveSearch: 'Insensible à la casse',
  compareTo: 'Comparer à',
  service: 'Services',
  chartMode: 'Graphique',
  chartTitle: 'Dépenses en %c par %k et par %g',
  EUR: 'euros',
  GBP: 'livres',
  INR: 'roupie indienne',
  USD: 'dollars',
  xAxisChartTitle: 'Temps ',
  inOperator: 'Inclus les valeurs',
  ninOperator: 'Exclus les valeurs',
  eqOperator: 'Égal à',
  neOperator: 'Est différent de',
  noScopeSaved: "Vous n'avez pas encore de filtre sauvegardé ?",
  noScopeSavedDescription: 'Cliquez ci-dessous pour en ajouter un.',
  operator: 'Opération',
  column: 'Colonne',
  values: 'Valeur(s)',
  addMoreBlocks: 'Ajouter un nouveau bloc',
  removeOperator: 'Supprimer',
  removeBlock: 'Supprimer le bloc',
  dragAndDropZoneText:
    'Glissez et déposez des filtres ici et sélectionnez une opération.',
  dragMoreFiltersText:
    'Glissez plus de filtres ou remplacez le filtre sélectionné.',
  resetFilters: 'Effacer',
  refresh: 'Actualiser',
  refreshAndClose: 'Appliquer',
  saveScope: 'Enregistrer le filtre',
  scopeName: 'Nom du filtre',
  showAnomalies: 'Afficher les anomalies',
  showWeekend: 'Afficher les weekends',
  showWeekendDescription:
    'Les weekends seront représentés sur les graphiques de dépenses par jour par des bandes légérement grisées.',
  optimizeRequest: 'Optimisation des requêtes',
  optimizeRequestDescription:
    'Afficher automatiquement les résultats par mois quand la période excède 60 jours',
  logarithmicMode: 'Échelle logarithmique',
  logarithmicModeDescription:
    'Une échelle logarithmique est particulièrement adaptée pour rendre compte des ordres de grandeur dans les graphiques.',
  untaggedMode: 'Afficher uniquement les dépenses sans étiquette',
  notSavedFilter: 'Filtre non enregistré',
  editAResource: 'Edit a resource',
  name: 'Nom',
  value: 'Valeur',
  origin: 'Origine',
  external: 'Externe',
  identifier: 'Identifiant',
  description: 'Description',
  addOrEditTag: 'Ajouter ou modifier une étiquette',
  optimizationPossible: 'Cette ressource peut être optimisée',
  relatedServices: 'Services associés',
  noServices: 'Aucun service associé',
  setAdditionalInformation: 'Ajouter des informations complémentaires',
  getOptimizations: 'Voir les optimisations pour cette ressource',
}
