module.exports = {
  account: 'Compte',
  project: 'Projet',
  application: 'Application',
  environment: 'Environnement',
  team: 'Équipe',
  cost_center: 'Centre de coût',
  management: 'Direction',
  provider: 'Fournisseur',
  domain: 'Domaine',
  country: 'Pays',
  entity: 'Entité',
  noScope:
    "Votre dimension est dépourvue de filtre et n'en hérite pas de ses dimensions parentes.",
  header: {
    title: 'Dimensions',
    noDescription: 'Aucune description',
  },
  export: {
    previewData: 'Prévisualiser les données',
    exportData: 'Exporter les données',
    exportOptions: "Options d'export",
    remainingRows: 'élément(s) supplémentaire(s)',
  },
  plural: {
    group: 'Regroupements',
    structure: 'Structures',
    account: 'Comptes',
    project: 'Projets',
    application: 'Applications',
    environment: 'Environnements',
    team: 'Équipes',
    cost_center: 'Centres de coût',
    management: 'Directions',
    domain: 'Domaines',
    provider: 'Fournisseurs',
    country: 'Pays',
    entity: 'Entités',
  },
  entities: {
    budget: {
      link: 'Lier un budget',
      linkDescription: 'Choississez une option :',
      unlink: 'Délier un budget',
      load: 'Charger un budget existant',
      loadDescription: 'À partir des budgets de votre organisation',
      create: 'Créer un nouveau budget',
      createDescription: 'A partir de cette dimension',
      select: 'Sélectionnez un budget :',
      loading: 'Chargement des budgets...',
      noResults: 'Aucun budget trouvé',
      noData: 'Aucun budget',
      title: 'Vos budgets',
      linker: 'Confirmez la liaison du budget suivant :',
    },
    dialog: {
      return: 'Retour',
      validate: 'Valider',
      next: 'Suivant',
      addSubDimension: 'Ajouter une sous-dimension',
      addDimension: 'Ajouter une dimension',
      dimensionType: 'Type de dimension',
      subDimensionType: 'Type de sous-dimension',
      dimensionName: 'Nom',
      dimensionDescription: 'Description',
      dimensionVisibility: 'Visibilité',
      dimensionFilters: 'Filtres',
      save: 'Enregistrer',
      close: 'Fermer',
    },
  },
  empty: {
    account: "Vous n'avez pas encore de dimension de type compte.",
    project: "Vous n'avez pas encore de dimension de type projet.",
    application: "Vous n'avez pas encore de dimension de type application.",
    environment: "Vous n'avez pas encore de dimension de type environnement.",
    team: "Vous n'avez pas encore de dimension de type équipe.",
    cost_center: "Vous n'avez pas encore de dimension de type centre de coût.",
    management: "Vous n'avez pas encore de dimension de type direction.",
  },
  visibility: {
    private: 'Privée',
    public: 'Publique',
  },
  index: {
    title: 'Bienvenue dans votre espace Dimensions !',
    subtitle: 'Cliquez sur un type de dimension à gauche pour commencer.',
  },
  alerts: {
    info: 'Bientôt, vous pourrez retrouver ici la liste des alertes de votre dimension et en créer de nouvelles.',
  },
  budgets: {
    search: 'Rechercher un budget',
  },
  card: {
    noDescription: 'Aucune description',
  },
  delete: {
    title: 'Zone de danger',
    subtitle: 'Supprimer la dimension',
    text: 'Cette action est irréversible et entraîne la suppression des dimensions rattachées à celle-ci. Les alertes et budgets spécifiques à cette dimension seront également supprimés. Les filtres associés seront quant à eux conservés.',
    button: 'Supprimer la dimension',
    alert:
      'La dimensions ne peut pas être supprimée car elle contient des sous-dimensions. Veuillez d’abord supprimer les sous-dimensions.',
  },
  explorers: {
    totalCostWithoutTaxes: 'Coût total',
    totalCostWithoutTaxesDescription:
      'Coût total hors taxes dans la période sélectionnée',
    trend: 'Tendance',
    trendDescription:
      'Évolution du coût total par rapport à la période précédente :',
    costPerHour: 'Coût par heure',
    costPerDay: 'Coût par jour',
    costPerHourDescription:
      "Le coût par heure est calculé selon le nombre d'heures dans la période sélectionnée.",
    costPerDayDescription:
      'Le coût par jour est calculé selon le nombre de jours dans la période sélectionnée.',
    granularity: 'Granularité',
    totalItems: 'Élément(s)',
    totalItemsDescription:
      'Nombre total d’éléments dans la période sélectionnée',
  },
  subDimensions: {
    search: 'Rechercher une sous-dimension',
  },
  filters: {
    configure: 'Configurer',
  },
  overview: {
    cloudCosts: 'Coût total (Cloud)',
    kubenetesCosts: 'Coût total (Kubernetes)',
    carbonEmissions: 'Émissions de CO2 (Carbon)',
  },
  drawer: {
    dimensionTitle: 'Menu',
    dimensionsTitle: 'Mes dimensions',
    settings: 'Paramètres',
    filters: 'Filtres',
    users: 'Utilisateurs',
    delete: 'Éditer',
    overview: 'Synthèse',
    subdimensions: 'Sous-dimensions',
    explorers: 'Explorateurs',
    budgets: 'Budgets',
    alerts: 'Alertes',
  },
  users: {
    info: 'Bientôt, vous pourrez retrouver ici la liste des utilisateurs à qui vous avez partagé cette dimension.',
  },
  charts: {
    unknownRegionCost:
      'Le montant des dépenses non assignées à une région est de',
  },
  benefits: {
    title: 'Consommations par modèle tarifaire',
    subtitle:
      "Évolution des dépenses à la demande VS avec engagements (Réservations) d'instance, Savings Plans, etc.)",
    onDemand: 'À la demande',
    onDemandDescription:
      'Montant des consommations payées sans engagement tarifaire',
    onDemandCovering: 'de couverture des consommations sans engagement',
    withBenefits: 'Avec engagement',
    withBenefitsDescription:
      'Montant des consommations couvertes par des engagements',
    withBenefitsCovering: 'de couverture des consommations avec engagement',
    benefitsCost: 'Coût des engagements',
    benefitsCostDescription:
      'Coût réel des engagements payés en avance chaque mois',
    benefitsCostCovering: "d'économies sur la consommation avec engagements",
  },
  edit: {
    title: 'Éditer la dimension',
    subtitle: 'Modifier les informations de la dimension',
    name: 'Nom de la dimension',
    description: 'Description',
    save: 'Enregistrer',
    cancel: 'Annuler',
  },
}
