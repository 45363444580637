import store from '@/store'
import moment from 'moment/moment'
import {
  useDateFormatter,
  useDateWithoutYearFormatter,
} from '@/composables/date'
import { ref } from 'vue'
import { useColor } from '@/composables/color'

export function useTrendFormatter(value, dec = 2) {
  return Intl.NumberFormat(store.state.user.lang, {
    style: 'percent',
    maximumFractionDigits: dec,
    minimumFractionDigits: dec,
  }).format(value / 100)
}

export function calculateTrendDate(granularity) {
  let dates = {
    startDate: null,
    endDate: null,
  }

  const formatDate = (date, includeYear = false) => {
    return includeYear
      ? useDateFormatter(date)
      : useDateWithoutYearFormatter(date)
  }

  const getDateRange = (start, end, includeYear = false) => {
    return {
      startDate: formatDate(start, includeYear),
      endDate: formatDate(end, includeYear),
    }
  }

  switch (granularity) {
    case 'last_seven_days': {
      const start = moment().subtract(7, 'days')
      dates = getDateRange(
        start.format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      )
      break
    }
    case 'last_thirty_days': {
      const start = moment().subtract(61, 'days')
      dates = getDateRange(
        start.format('YYYY-MM-DD'),
        start.add(30, 'days').format('YYYY-MM-DD'),
      )
      break
    }
    case 'last_month': {
      const start = moment().subtract(2, 'months').startOf('month')
      dates = getDateRange(
        start.format('YYYY-MM-DD'),
        start.endOf('month').format('YYYY-MM-DD'),
      )
      break
    }
    case 'current_month': {
      const start = moment().subtract(1, 'months').startOf('month')
      dates = getDateRange(
        start.format('YYYY-MM-DD'),
        moment().subtract(1, 'months').format('YYYY-MM-DD'),
      )
      break
    }
    case 'current_year': {
      const start = moment().subtract(1, 'years').startOf('year')
      const end = moment().subtract(1, 'years')
      dates = getDateRange(
        start.format('YYYY-MM-DD'),
        end.format('YYYY-MM-DD'),
        true,
      )
      break
    }
    default:
      break
  }

  return dates
}

export function useTrendStyle() {
  const positiveTrend = ref('#0f8b00')
  const negativeTrend = ref('#F44336')
  const neutralTrend = ref('#dd6600')

  const getBaseColor = (value, isAnomalous) => {
    if (isAnomalous) {
      return negativeTrend.value
    }
    return value > 0
      ? negativeTrend.value
      : value < 0
      ? positiveTrend.value
      : neutralTrend.value
  }

  const getBackgroundColor = (isActive, value, isDarkMode, isAnomalous) => {
    return isDarkMode
      ? !isActive
        ? 'grey darken-3'
        : getBaseColor(value, isAnomalous)
      : isActive
      ? useColor().lightenHexColor(getBaseColor(value, isAnomalous), 0.8)
      : 'grey lighten-2'
  }

  const getTextColor = (isActive, value, isDarkMode, isAnomalous) => {
    return isDarkMode
      ? !isActive
        ? 'grey lighten-2'
        : 'white'
      : isActive
      ? getBaseColor(value, isAnomalous)
      : 'grey darken-2'
  }

  const getInfoColor = (value, isAnomalous) => {
    if (isAnomalous) {
      return negativeTrend.value
    }

    const colors = [
      negativeTrend.value,
      neutralTrend.value,
      positiveTrend.value,
    ]
    const factors = [0.2, 0.3, 0.4]
    const index = value > 0 ? 0 : value < 0 ? 2 : 1
    return useColor().lightenHexColor(colors[index], factors[index])
  }

  const getIcon = (value) => {
    if (value === 0) {
      return 'mdi-trending-neutral'
    }
    return value >= 0 ? 'mdi-trending-up' : 'mdi-trending-down'
  }

  return {
    getBackgroundColor,
    getTextColor,
    getInfoColor,
    getIcon,
  }
}
