<template>
  <v-menu
    v-model="menu"
    v-if="hasWorkflows"
    :close-on-content-click="false"
    right
    attach="#workflow-display"
    class="menu"
    close-delay="400"
    bottom
    content-class="elevation-0"
    nudge-bottom="55"
    nudge-left="243"
  >
    <template #activator="{ on }">
      <div
        id="workflow-display"
        class="workflow-btn-container pa-0 ma-0"
        :style="menu ? { backgroundColor: primaryColor } : {}"
        @click="toggleMenu"
        v-on="on"
      >
        <state-icon :name="currentIcon" :color="currentColor" :size="38" />
      </div>
    </template>

    <v-card flat outlined width="300px" class="card">
      <v-card-title class="title">
        {{ lang.workflow.title }}
      </v-card-title>
      <v-divider />
      <template v-if="workflowError">
        <workflow-section
          :workflows="errorWorkflows"
          :status="'error'"
          :icon="iconMap.error"
          :color="colorMap.error"
        />
        <v-expansion-panels
          v-if="doneWorkflows.length > 0 || runningWorkflows.length > 0"
        >
          <v-expansion-panel>
            <v-divider />
            <v-expansion-panel-header @click.stop flat>
              {{ $lang.workflow.actions }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider />
              <workflow-section
                v-if="doneWorkflows.length"
                :workflows="doneWorkflows"
                :status="'done'"
                :icon="iconMap.done"
                :color="colorMap.done"
              />
              <workflow-section
                v-if="runningWorkflows.length"
                :workflows="runningWorkflows"
                :status="'running'"
                :icon="iconMap.running"
                :color="colorMap.running"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template v-else>
        <workflow-section
          v-if="doneWorkflows.length"
          :workflows="doneWorkflows"
          :status="'done'"
          :icon="iconMap.done"
          :color="colorMap.done"
        />
        <workflow-section
          v-if="runningWorkflows.length"
          :workflows="runningWorkflows"
          :status="'running'"
          :icon="iconMap.running"
          :color="colorMap.running"
        />
      </template>
    </v-card>
  </v-menu>
</template>

<script setup>
import { computed, ref, onMounted, getCurrentInstance, onUnmounted } from 'vue'

import { useWorkflowStyle } from '@/composables/workflow'
import { useColor } from '@/composables/color'

import WorkflowsService from '@/services/workflow'

import StateIcon from '@/components/global/StateIcon.vue'
import WorkflowSection from '@/components/layouts/workflow/WorkflowSection.vue'

const { lightenHexColor } = useColor()
const { getBaseColor, getIcon } = useWorkflowStyle()

const service = new WorkflowsService()
const instance = getCurrentInstance().proxy
const lang = computed(() => instance.$lang)
const menu = ref(false)
let intervalId = null
const workflows = ref({ done: [], running: [], error: [] })

const primaryColor = computed(() =>
  lightenHexColor(instance.$vuetify.theme.themes.light.primary, 0.7),
)

const doneWorkflows = computed(() => workflows.value.done || [])
const runningWorkflows = computed(() => workflows.value.running || [])
const errorWorkflows = computed(() => workflows.value.error || [])

const workflowError = computed(() => errorWorkflows.value.length > 0)
const hasWorkflows = ref(false)

const currentStatus = computed(() => {
  if (workflowError.value) return 'error'
  if (runningWorkflows.value.length) return 'running'
  if (doneWorkflows.value.length) return 'done'
  return 'running'
})

const iconMap = computed(() => ({
  error: getIcon('error'),
  running: getIcon('running'),
  done: getIcon('done'),
}))

const colorMap = computed(() => ({
  error: getBaseColor('error'),
  running: getBaseColor('running'),
  done: getBaseColor('done'),
}))

const currentIcon = computed(() => iconMap.value[currentStatus.value])
const currentColor = computed(() => colorMap.value[currentStatus.value])

const toggleMenu = () => {
  menu.value = !menu.value
}

const checkWorkflow = () => {
  hasWorkflows.value =
    workflows.value.done.length > 0 ||
    workflows.value.running.length > 0 ||
    workflows.value.error.length > 0
}

const fetchWorkflows = async () => {
  workflows.value = await service.get([
    'data_import',
    'aggregate_dimensions',
    'check_budgets',
    'check_alerts',
  ])
  checkWorkflow()
  updateInterval()
}

const updateInterval = () => {
  clearInterval(intervalId)
  const intervalTime = runningWorkflows.value.length > 0 ? 10000 : 30000
  intervalId = setInterval(fetchWorkflows, intervalTime)
}

onMounted(() => {
  fetchWorkflows()
})

onUnmounted(() => {
  clearInterval(intervalId)
})
</script>

<style scoped>
.workflow-btn-container {
  width: 56px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  border-left: 1px solid #e0e0e0;
}

.workflow-btn-container:hover {
  background-color: rgba(219, 219, 219, 0.3);
  cursor: pointer;
}

>>> .card {
  z-index: 9999;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.title {
  font-size: 16px !important;
  font-family: 'Mangueira', sans-serif !important;
  font-weight: 300;
  padding-bottom: 8px;
  padding-top: 8px;
}

.v-expansion-panel::before {
  box-shadow: none;
}
</style>
