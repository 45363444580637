export const budgetRoutes = [
  {
    path: '/budgets',
    component: () => import('../views/Budgets.vue'),
    meta: { permission: 'budget.index', miniNavBar: true },
    children: [
      {
        name: 'budgets',
        path: '',
        meta: { permission: 'budget.index' },
        components: {
          table: () => import('../components/budget/BudgetTable.vue'),
          groupTable: () => import('../components/budget/BudgetGroupTable.vue'),
        },
      },
      {
        name: 'budget-create',
        path: 'create',
        meta: { permission: 'budget.store' },
        component: () => import('../views/BudgetForm.vue'),
      },
      {
        name: 'budget-renewal',
        path: 'renewal',
        component: () => import('../views/BudgetForm.vue'),
      },
      {
        name: 'budget-update',
        path: 'update',
        component: () => import('../views/BudgetForm.vue'),
      },
      {
        name: 'budget-group',
        path: '/budgets/group/:budgetGroupId',
        components: {
          group: () => import('../components/budget/BudgetGroupDetail.vue'),
        },
        meta: { permission: 'budgetgroup.show', miniNavBar: true },
      },
      {
        name: 'budget',
        path: ':id',
        component: () => import('../views/BudgetView.vue'),
        children: [
          {
            name: 'overview',
            path: '',
            component: () =>
              import('../components/budget_v2/BudgetOverview.vue'),
          },
          {
            name: 'periods',
            path: 'periods',
            component: () =>
              import('../components/budget_v2/BudgetPeriods.vue'),
          },
          {
            name: 'optimizations',
            path: 'optimizations',
            component: () =>
              import('../components/budget_v2/BudgetOptimizations.vue'),
          },
          {
            name: 'parameters',
            path: 'parameters',
            component: () =>
              import('../components/budget_v2/BudgetSettings.vue'),
          },
        ],
        redirect: { name: 'overview' },
      },
      {
        path: '/budget/:pathMatch(.*)*',
        redirect: (to) => {
          return { path: `/budgets/${to.params.pathMatch}` }
        },
      },
    ],
  },
]
