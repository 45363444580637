<template>
  <div v-if="visible && notification" class="snack-container">
    <v-snackbar
      :color="notification.color"
      elevation="0"
      v-model="visible"
      timeout="-1"
      centered
      top
    >
      <div class="d-flex align-center pl-2 snackbar-content">
        <v-icon class="mr-3" color="white">{{ notification.icon }}</v-icon>
        <span>
          {{ lang.error.default[notification.key] }}
        </span>
      </div>
      <v-progress-linear
        class="progress-bar"
        :height="5"
        color="white"
        rounded
        :value="progressValue"
      />
    </v-snackbar>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, ref, onMounted, onUnmounted } from 'vue'
import moment from 'moment'

const instance = getCurrentInstance().proxy
const store = computed(() => instance.$store)
const lang = computed(() => instance.$lang)

const props = defineProps({
  notification: Object,
})

const visible = ref(true)
const progressValue = ref(100)
let animationFrameId
let startTime

const startAnimation = () => {
  startTime = moment()
  const duration = moment.duration(5000)

  const animate = () => {
    const elapsedTime = moment().diff(startTime)
    const progress = Math.max(0, 1 - elapsedTime / duration.asMilliseconds())
    progressValue.value = progress * 100

    if (progress > 0) {
      animationFrameId = requestAnimationFrame(animate)
    } else {
      setTimeout(() => {
        closeNotification()
      }, 100)
    }
  }

  animationFrameId = requestAnimationFrame(animate)
}

const closeNotification = () => {
  cancelAnimationFrame(animationFrameId)
  visible.value = false
  store.value.commit('removeAllMessages')
}

onMounted(() => {
  startAnimation()
})

onUnmounted(() => {
  cancelAnimationFrame(animationFrameId)
})
</script>

<style scoped>
.progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

>>> .v-snack__content {
  padding: 0 12px 0 8px;
}
</style>
