export function useWorkflowStyle() {
  const statusConfig = {
    done: { color: '#0f8b00', icon: 'checkSvg' },
    running: { color: '#027FC7', icon: 'runningSvg' },
    error: { color: '#F44336', icon: 'errorSvg' },
  }

  const getBaseColor = (status) => {
    return statusConfig[status].color || '#000000'
  }

  const getIcon = (status) => {
    return statusConfig[status].icon || 'runningSvg'
  }

  return {
    getBaseColor,
    getIcon,
  }
}
