<template>
  <div class="icon-container">
    <component
      :is="component"
      class="icon"
      :color="iconColor"
      :class="{ 'rotate-animation': name === 'runningSvg' }"
    />
  </div>
</template>

<script setup>
import { markRaw, computed, ref } from 'vue'
import { useColor } from '@/composables/color'

import CheckSvg from '@/assets/svgComponents/checkSvg.vue'
import RunningSvg from '@/assets/svgComponents/runningSvg.vue'
import ErrorSvg from '@/assets/svgComponents/errorSvg.vue'

const { lightenHexColor } = useColor()

const props = defineProps({
  color: {
    type: String,
  },
  size: {
    type: Number,
    default: 32,
  },
  name: {
    type: String,
  },
})

const size = computed(() => props.size + 'px')
const iconSize = computed(() => props.size * 0.65 + 'px')
const iconColor = computed(() => props.color)
const backgroundColor = computed(() => lightenHexColor(props.color, 0.7))

const component = computed(() => {
  const icon = props.name
  const components = {
    checkSvg: CheckSvg,
    runningSvg: RunningSvg,
    errorSvg: ErrorSvg,
  }
  return markRaw(components[icon])
})
</script>

<style scoped>
.icon-container {
  height: v-bind(size);
  width: v-bind(size);
  background-color: v-bind(backgroundColor);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: v-bind(iconSize);
  height: v-bind(iconSize);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.rotate-animation {
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
