module.exports = {
  header: {
    title: 'Explorers',
    cloud: 'Cloud',
    kubernetes: 'Kubernetes',
    carbon: 'Carbon',
    filters: 'Filters',
    from: 'From',
    to: 'to',
    group: 'Group costs',
  },
  periods: {
    last7Days: '7 days',
    last30Days: '30 days',
    last60Days: '60 days',
    last90Days: '90 days',
    last6Months: '6 months',
    last12Months: '12 months',
    thisYear: 'This year',
    lastYear: 'Last year',
    lastMonth: 'Last month',
    select: 'Select a period :',
    update: 'Update period',
    manual: 'Or select dates manually :',
    last_seven_days: 'Last 7 days',
    last_thirty_days: 'Last 30 days',
    current_month: 'Current month',
    last_month: 'Last month',
    current_year: 'Current year',
    custom: 'Custom period',
  },
  groups: {
    name: 'Name',
    provider: 'Provider',
    category: 'Category',
    product_code: 'Product',
    service_code: 'Service',
    region: 'Region',
    resource_id: 'Resource ID',
    resource_path: 'Resource ID',
    pricing_model: 'Pricing model',
    usage_account_id: 'Account',
    usage_type: 'Usage',
    usage_quantity: 'Usage quantity',
    usage_unit: 'Usage unit',
    original_cost: 'Cost without taxes (before conversion)',
    conversion_rate: 'Conversion rate',
    tags: 'Tags',
    cost_center: 'Cost center',
    cluster_id: 'Cluster ID',
    namespace: 'Namespace',
    item_type: 'Charge type',
    pod: 'Pod',
    container: 'Container',
    controller_king: 'Controller kind',
    controller_name: 'Controller name',
    labels: 'Labels',
    cost: 'Cost without taxes',
    groupCostsBy: 'Group costs',
    defaultGroups: 'Default groups',
    customGroups: 'Custom groups',
    noResults: 'No results.',
    searchGroup: 'Search a group',
  },
  chart: {
    title: 'Cost by',
    search: 'Search a',
    subtitle: 'Cost without taxes displayed in',
    totalCost: 'Total cost without taxes',
    others: 'Others',
    othersDescription:
      'Costs less than 1% of the total amount are grouped in the "Others" category.',
    organizationChartDescription:
      'Costs are grouped by sub-dimension. For optimal display, we recommend that you do not create more than 4 levels of sub-dimensions.',
    organizationChartEmpty:
      "This dimension doesn't have any sub-dimension yet.",
    options: 'chart options',
    hidden: 'Hide',
    expend: 'Expend',
    type: 'Chart type',
    otherOptions: 'Other options',
    logarithmic: 'Logarithmic scale',
    costDisplayedIn: 'Costs displayed in',
    andBy: 'and by',
    granularity: 'Granularity',
  },
  table: {
    unassigned: 'Unassigned',
    options: 'table options',
    hidden: 'Hide',
    expend: 'Expend',
    addColumns: 'Add columns',
    costDisplayedIn: 'Costs displayed in',
  },
  export: {
    title: 'Export data',
    description: 'You can export the data in CSV format.',
    table: 'Table data',
    chart: 'Chart data',
    separator: 'Decimal separator',
    delimiter: 'Delimiter',
    download: 'Download',
    waitingForDownload: 'Click the button below to start downloading the file.',
    finishingDownload: 'Your download is starting...',
    errorDecimalAndSeparator:
      'You cannot use the same character for both the delimiter and the decimal separator.',
    separatorLabel: 'Decimal separator',
    delimiterLabel: 'Delimiter',
    exportOptions: 'Export options',
  },
}
