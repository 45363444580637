<template>
  <div
    :class="{
      'state-container': status !== 'error',
      'pt-2': status === 'error',
    }"
  >
    <div class="col-12 pa-0 ma-0">
      <span class="font-mangueira-m">{{ workflowName }}</span>
    </div>
    <div class="col-12 pa-0 ma-0">
      <v-progress-linear
        :height="12"
        :value="duration"
        :indeterminate="isIndeterminate"
        :color="color"
        :style="{ backgroundColor: '#E0E0E0' }"
        class="progress-bar"
      >
        <span v-if="status !== 'running'" class="font-mangueira-m duration">
          {{ duration }} %
        </span>
      </v-progress-linear>
    </div>
  </div>
</template>
<script setup>
import { computed, getCurrentInstance, toRefs } from 'vue'
const instance = getCurrentInstance().proxy
const lang = computed(() => instance.$lang)

const props = defineProps({
  workflow: {
    type: Object,
  },
  color: {
    type: String,
  },
  status: {
    type: String,
  },
})

const { slug, status } = toRefs(props.workflow)

const workflowName = computed(() => {
  const workflowLabels = {
    data_import: lang.value.workflow.dataImport,
    aggregate_dimensions: lang.value.workflow.aggregateDimensions,
    check_budgets: lang.value.workflow.checkBudgets,
    check_alerts: lang.value.workflow.checkAlerts,
  }

  return workflowLabels[slug.value] || slug.value
})

const duration = computed(() => {
  const statusMap = {
    error: 10,
    running: 0,
    done: 100,
  }
  return statusMap[props.status] ?? 0
})

const isIndeterminate = computed(() => {
  return props.status === 'running'
})

const durationColor = computed(() => {
  return duration.value <= 50 ? 'black' : 'white'
})
</script>
<style scoped>
.state-container {
  height: 40px;
}

.progress-bar {
  border-radius: 10px;
}

.duration {
  color: v-bind(durationColor);
  font-size: 10px;
  padding-top: 2px;
}
</style>
