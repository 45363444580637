module.exports = {
  title: 'Vos processus',
  done: 'Terminées',
  running: 'En cours',
  error: 'Un incident a été identifié sur le processus suivant :',
  errorTeamText:
    'Notre équipe en a été informé et travaille à la résolution de cet incident.',
  errorQuestion: 'Vous avez une question ?',
  errorBtnText: 'Contacter le support',
  actions: 'Autres actions',
  dataImport: 'Import des données',
  aggregateDimensions: 'Calcul des dimensions',
  checkBudgets: 'Calcul des budgets',
  checkAlerts: 'Calcul des alerts',
}
