<template>
  <div class="row no-gutters section-container pa-4 pt-2">
    <div class="col-12">
      <div class="row no-gutters section-title">
        <span class="font-mangueira status-text">
          {{ $lang.workflow[status] }}
        </span>
        <state-icon
          v-if="status !== 'error'"
          :name="icon"
          :color="color"
          :size="18"
          class="status-icon"
        ></state-icon>
      </div>
    </div>
    <div class="col-12">
      <workflow-state
        v-for="workflow in workflows"
        :key="workflow.id"
        :workflow="workflow"
        :status="status"
        :color="color"
      ></workflow-state>
    </div>
    <div v-if="status === 'error'" class="col-12 pa-0 pt-3 text-center">
      <div class="font-mangueira error-team-text pt-2">
        {{ $lang.workflow.errorTeamText }}
      </div>
      <div class="font-mangueira-m error-time-text">
        {{ useDateHourFormatter(workflows[0].started_at) }}
      </div>
      <div class="mt-3 mb-3">
        <span class="question-text font-mangueira">
          {{ $lang.workflow.errorQuestion }}
        </span>
        <v-btn
          class="col-12 email-btn"
          elevation="0"
          :color="color"
          href="mailto:support@lota.cloud"
        >
          <v-icon color="white" class="mr-1">mdi-email</v-icon>
          <span class="error-btn-text font-mangueira">
            {{ $lang.workflow.errorBtnText }}
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script setup>
import WorkflowState from '@/components/layouts/workflow/WorkflowState.vue'
import StateIcon from '@/components/global/StateIcon.vue'
import { computed, getCurrentInstance, toRefs } from 'vue'
import { useDateHourFormatter } from '@/composables/date'

const instance = getCurrentInstance().proxy
const $lang = computed(() => instance.$lang)

const props = defineProps({
  workflows: Array,
  status: String,
  icon: String,
  color: String,
})

const { workflows } = toRefs(props)
</script>
<style scoped>
.section-container {
  display: flex;
  align-items: center;
}

.section-title {
  display: flex;
  align-items: center;
}

.status-text {
  margin: 6px 6px 4px 0;
}

.email-btn {
  width: 210px;
}

.error-btn-text {
  color: white;
}

.error-team-text {
  font-size: 14px;
  line-height: 14px;
}

.error-time-text {
  font-size: 12px;
}
</style>
